/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "썸네일 by ", React.createElement("a", {
    href: "https://pixabay.com/users/larisa-k-1107275/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=163518"
  }, "Larisa Koshkina"), " from ", React.createElement("a", {
    href: "https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=163518"
  }, "Pixabay")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/"
  }, "Twitter")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://status.twitterstat.us/"
  }, "인증서 만료된 Twitter Status")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/TwitterA11y"
  }, "Twitter 접근성 계정 TwitterA11y 계정")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://careers.twitter.com/en"
  }, "Twitter 채용사이트")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://radar.cloudflare.com/domains"
  }, "Cloudflare Radar")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/eastdakota/status/1678065025750294532"
  }, "Cloudflare CEO인 Matthew Prince의 트윗")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://zdnet.co.kr/view/?no=20231005111621"
  }, "신한카드 결제 먹통 사태, 피해 보상 범위는")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.inews24.com/view/1638682"
  }, "금감원 \"신한카드 결제 오류, 인증서 만료와 무관”")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%A0%9C%EC%9E%84%EC%8A%A4_%EA%B3%A0%EC%8A%AC%EB%A7%81"
  }, "제임스 고슬링")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.chemicalnews.co.kr/news/articleView.html?idxno=6163"
  }, "‘LG 소프트웨어 개발자 콘퍼런스 2023' 열려... 1천여 명 참여해 기술 공유")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://rubyonrails.org/"
  }, "Rails")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://dhh.dk/"
  }, "David Heinemeier Hansson")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://hotwired.dev/"
  }, "Hotwire")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://turbo.hotwired.dev/"
  }, "Turbo")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://world.hey.com/dhh/turbo-8-is-dropping-typescript-70165c01"
  }, "Turbo 8 is dropping TypeScript")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/hotwired/turbo/pull/971"
  }, "Remove TypeScript")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://world.hey.com/dhh/open-source-hooliganism-and-the-typescript-meltdown-a474bfda"
  }, "Open source hooliganism and the TypeScript meltdown")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://kamal-deploy.org/"
  }, "Kamal")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://world.hey.com/dhh/how-it-started-how-it-s-going-baefaf09"
  }, "How it started / how it's going")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
